<template>
	<div class="content">
		<!-- 添加店铺 -->
		<a-button class="mb-15" type="primary" @click="add">
		  添加小店
		</a-button>
		
		<!-- 表格 -->
		<Table
		:columns="columns" 
		:datasource="datasource" 
		:pagination="pagination"
		@gettabledata="gettabledata" 
		@getshow = "getshow"
		@modify = "modify"
		@del = "del"
		@setcommission="setcommission"
		@bindperson="bindperson"
		>
		</Table>
		
		<!-- 修改 -->
		<Modify ref="modify" :record="record" @modifynav="sumbitmodify"></Modify>
		
		<!-- Add -->
		<Add ref="add" :record="record" @modifynav="sumbitadd"></Add>
		
		<!-- 设置佣金 -->
		<Commission ref="commission" :record="record" @modifynav="sumbitcommission"></Commission>
		
		<!-- 绑定人员 -->
		<Binding ref="bind" :record="record" @modifynav="submitbind"></Binding>
	</div>
</template>

<script>
	import {mapState,mapGetters,mapMutations,mapActions} from "vuex"
	import Table from "../../components/branchshop/table.vue"
	import Modify from "../../components/branchshop/modify.vue"
	import Add from "../../components/branchshop/add.vue"
	import Commission from "../../components/branchshop/commission.vue"
	import Binding from "../../components/branchshop/binding.vue"
	export default {
		components:{
			Table,
			Modify,
			Add,
			Commission,
			Binding
		},
		data() {
			return {
				record:{
					data:"",
					classify:""
				},
				pagination:{
					pageSize:4,
					current:1,
					totalPage:0,
					total:0,
					keyword:"",
					platform:""
				},
				columns :[
				  {
				    title: 'ID',
					key:"id",
				    dataIndex: 'id',
					align: 'center' 
				  },
				  {
				    title: '小店名称',
				  	key:"name",
				    dataIndex: 'name',
				  	align: 'center' 
				  },
				  {
				    title: '打款账号',
				  	key:"receive_payment",
				    dataIndex: 'receive_payment',
				  	align: 'center' 
				  },
				  {
				    title: '分享二维码',
				  	key:"wx_qr",
				    dataIndex: 'wx_qr',
				  	align: 'center' ,
					slots: { title: 'wx_qr' }, //插槽列
					scopedSlots: { customRender: "wx_qr" },//插槽列数据
				  },
				  {
				    title: '绑定人员',
				  	key:"userid",
				    dataIndex: 'userid',
				  	align: 'center' ,
					slots: { title: 'userid' }, //插槽列
					scopedSlots: { customRender: "userid" },//插槽列数据
				  },
				  {
				    title: '是否禁止',
				  	key:"status",
				    dataIndex: 'status',
				  	align: 'center' ,
					slots: { title: 'status' }, //插槽列
					scopedSlots: { customRender: "status" },//插槽列数据
				  },
				  {
				    title: '操作',
					key:"action",
				    dataIndex: 'action',
					slots: { title: 'action' }, //插槽列
					scopedSlots: { customRender: "action" },//插槽列数据
					align: 'center' 
				  },
				],
				datasource:[
					{
						id:1,
						name:"家电清洗",
						wx_qr:"服务",
						renyuan:"icon",
						status:false,
					},
				]
			}
		},
		created() {
			// 获取表格数据
			this.gettabledata(this.pagination)
		},
		methods:{
			...mapActions({
				getupdate:"branchshopstoreinfo/getupdate",
				getadd:"branchshopstoreinfo/getadd",
				getshoplist:"branchshopstoreinfo/getshoplist",
				getstatus:"branchshopstoreinfo/getstatus",
				getcategory:"branchshopstoreinfo/getcategory",
				getcommission:"branchshopstoreinfo/getcommission",
				getbind:"branchshopstoreinfo/getbind"
			}),
			/*
			 *获取数据
			 *pagination 分页参数
			 * 初始化和点击执行
			 */
			async gettabledata(pagination){
				 let data = await this.getshoplist(pagination)
				 console.log(data,"店铺");
				 this.pagination.current = pagination.current
				 this.pagination.total = data.data.total
				 this.pagination.totalPage = data.data.totalPage
				 this.datasource = data.data.data
			
			},
			// 打开添加弹窗
			add(){
				// 每一行数据record
				let record = {
					name:"",
					receive_payment:"",
				}
				this.record.data = record
				this.$refs.add.show = true				
			},
			// 提交添加
			async sumbitadd(payload){
				console.log(payload,"提交添加店铺信息");
				let msg = await this.getadd(payload)
				this.$message.success(msg)
				this.gettabledata(this.pagination)
				this.$refs.add.show = false
			},
			// 显示和禁止
			async getshow(id){
				let msg = await this.getstatus(id)
				this.$message.success(msg)
				this.gettabledata(this.pagination)
			},
			// 打开修改弹窗
			async modify(record){
				console.log("record",record);
				// // 每一行数据record
				this.record.data = record
				this.$refs.modify.show = true
				
			},
			// 提交修改
			async sumbitmodify(payload){
				console.log(payload,"提交修改店铺信息");
				let msg = await this.getupdate(payload)
				this.$message.success(msg)
				this.gettabledata(this.pagination)
				this.$refs.modify.show = false
			},
			// 打开设置佣金
			async setcommission(record){
				let payload = {
					keyword:""
				}
				let data = await this.getcategory(payload)
				
				this.record.data = record
				data.map(item=>{
					item.proportion = 10
				})
				this.record.classify = data
				this.$refs.commission.show = true
			},
			// 提交佣金设置
			async sumbitcommission(payload){
				let msg = await this.getcommission(payload)
				this.$message.success(msg)
				this.gettabledata(this.pagination)
				this.$refs.commission.show = false
			},
			// 绑定人员
			bindperson(record){
				console.log(record,"传递参数");
				this.record.data = record
				this.$refs.bind.show = true
			},
			// 提交绑定
			async submitbind(payload){
				console.log(payload,"提交绑定参数");
				let msg = await this.getbind(payload)
				this.$message.success(msg)
				this.gettabledata(this.pagination)
				this.$refs.bind.show = false
				
			},
			// 删除
			async del(e){
				// 
				console.log(e);
				let msg = await this.getdel(e.id)
				this.$message.success(msg)
				this.gettabledata(this.pagination)
			}
		}
		
	}
</script>

<style>
</style>
