<template>
	<div>
		<a-modal okText="确定" cancelText="取消" v-model="show" title="设置佣金" @ok="comfirm">
			<template>
				<!-- 表单验证 -->
				<a-form ref="collectionForm" :form="form">
					<!-- id -->
					<a-form-item label="小店ID">
						<a-input disabled v-decorator="[
							'id',
							{
							  initialValue: record.data.id,
							  rules: [{ required: true, message: `请填写小店ID`, whitespace: true }],
							},
						  ]" />
					</a-form-item>
					<a-form-item label="小店ID">
						<a-input disabled v-model="record.data.name" />
					</a-form-item>
					<!-- 名称 -->
					<a-form-item label="设置分类佣金">
						  <a-table :columns="columns" :pagination="false" :data-source="record.classify" :row-key="(record, index) => index" bordered>
						    <template
						      v-for="col in ['proportion']"
						      :slot="col"
						      slot-scope="text, record, index"
						    >
						      <div :key="col">
						        <a-input
						          v-if="record.editable"
						          style="margin: -5px 0"
						          :value="text"
						          @change="e => handleChange(e.target.value, record.id, col)"
						        />
						        <template v-else>
						          {{ text }}
						        </template>
						      </div>
						    </template>
						    <template slot="operation" slot-scope="text, record, index">
						      <div class="editable-row-operations">
						        <span v-if="record.editable">
						          <a @click="() => save(record.id)">保存</a>
						          <a-popconfirm title="Sure to cancel?" @confirm="() => cancel(record.id)">
						            <a>取消</a>
						          </a-popconfirm>
						        </span>
						        <span v-else>
						          <a :disabled="editingKey !== ''" @click="() => edit(record.id)">修改</a>
						        </span>
						      </div>
						    </template>
						  </a-table>
					</a-form-item>								
					
				</a-form>
			</template>


		</a-modal>
	</div>
</template>

<script>

	export default {
		props: {
			record: Object
		},
		
		data() {
			return {
				show: false,
				isresources: false,
				form: this.$form.createForm(this, {
					name: 'coordinated'
				}),
				columns:[
					{
						 title: 'ID',
						 key:"id",
						 dataIndex: 'id',
						 align: 'center' 
					},
					{
						 title: '分类名称',
						 key:"name",
						 dataIndex: 'name',
						 align: 'center' 
					},
					{
						 title: '设置比列(%)',
						 key:"proportion",
						 dataIndex: 'proportion',
						 align: 'center' ,
						 scopedSlots: { customRender: 'proportion' },
					},
					{
						title: '操作',
						dataIndex: 'operation',
						scopedSlots: { customRender: 'operation' },
					},
				],
				datasource:"",
				cacheData:"",
				editingKey:"",
			}
		},

		created() {

		},
		methods: {
			handleChange(value, key, column) {
			      const newData = [...this.record.classify];
			      const target = newData.filter(item => key === item.id)[0];
			      if (target) {
			        target[column] = value;
			        this.record.classify = newData;
			      }
			    },
			    edit(key) {
			      const newData = [...this.record.classify];
			      const target = newData.filter(item => key === item.id)[0];
			      this.editingKey = key;
			      if (target) {
			        target.editable = true;
			        this.record.classify = newData;
			      }
				  console.log(this.record.classify,"修改");
			    },
			    save(key) {
			      const newData = [...this.record.classify];
				  this.cacheData = this.record.classify.map(item => ({ ...item }));
			      const newCacheData = [...this.cacheData];
			      const target = newData.filter(item => key === item.id)[0];
			      const targetCache = newCacheData.filter(item => key === item.id)[0];
			      if (target && targetCache) {
			        delete target.editable;
			        this.record.classify = newData;
			        Object.assign(targetCache, target);
			        this.cacheData = newCacheData;
					// console.log(this.cacheData);
			      }
			      this.editingKey = '';
			    },
			    cancel(key) {
			      const newData = [...this.record.classify];
			      const target = newData.filter(item => key === item.id)[0];
			      this.editingKey = '';
			      if (target) {
			        Object.assign(target, this.cacheData.filter(item => key === item.id)[0]);
			        delete target.editable;
			        this.record.classify = newData;
			      }
			    },
			comfirm(e) {
				console.log(e);
				const form = this.$refs.collectionForm.form;
				form.validateFields((err, values) => {
					if (err) {
						return;
					}
					console.log('Received values of form: ', values);
					console.log(this.record.classify,"分类数据佣金");

					values.data = JSON.stringify(this.record.classify)
					// 执行提交方法
					this.$emit("modifynav", values)
				});
			},
		}
	}
</script>

<style scoped lang="less">

</style>
