<template>
	<div>
		<a-modal okText="确定" cancelText="取消" v-model="show" title="添加小店" @ok="comfirm">
			<template>
				<!-- 表单验证 -->
				<a-form ref="collectionForm" :form="form">										
					<!-- 分店名称 -->
					<a-form-item label="小店名称">
						<a-input v-decorator="[
							'name',
							{
							  initialValue: record.data.name,
							  rules: [{ required: true, message: `请填写小店名称`, whitespace: true }],
							},
						  ]" />
					</a-form-item>
					
					<!-- 打款账号 -->
					<a-form-item label="打款账号">
						<a-input v-decorator="[
							'receive_payment',
							{
							  initialValue: record.data.receive_payment,
							  rules: [{ required: true, message: `请填写打款账号`, whitespace: true }],
							},
						  ]" />
					</a-form-item>
																																				
				</a-form>
				
			</template>


		</a-modal>
	</div>
</template>

<script>

	export default {
		props: {
			record: Object
		},

		data() {
			return {
				show: false,
				isresources: false,
				form: this.$form.createForm(this, {
					name: 'coordinated'
				}),
			}
		},

		created() {

		},
		methods: {

			comfirm(e) {
				console.log(e);
				const form = this.$refs.collectionForm.form;
				form.validateFields((err, values) => {
					if (err) {
						return;
					}
					console.log('Received values of form: ', values);
					// 执行提交方法
					this.$emit("modifynav", values)
				});
			},
		}
	}
</script>

<style scoped lang="less">

</style>
