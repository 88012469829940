<template>
	<div>
		<a-card class="acard f-ac">
			<div class="f-ac">
				<div class="f-ac">
					<div class="tips">关键词：</div>
					<a-input-search class="ainputsearch" placeholder="请输入搜索内容" enter-button
						@search="onSearch" />
				</div>
			</div>
		</a-card>
		<!-- 
	  bordered :边框线
	  columns：表头
	  slots: { title: '是否显示' }, //插槽列
	  scopedSlots: { customRender: '是否显示' },//插槽列数据
	  @change="handleTableChange" //分页、排序、筛选变化时触发
	  -->
		<a-table 
		bordered 
		:columns="columns" 
		:data-source="datasource" 
		:pagination="pagination" 
		:row-selection="{ selectedRowKeys: selectedRowKeysArray, onChange: onSelectChange, type: 'radio' }"
		row-key="id"
		@change="handleTableChange"
		>
			<!-- 自定义表格列显示内容 -->
			<!-- 头像 -->
			<template  slot="avatar" slot-scope="avatar">
				<!-- {{show}} -->
				<img class="img-pic" :src="avatar" alt="" />
			</template>

		</a-table>
	</div>
</template>
<script>
	export default {
		props: {
			columns: Array,
			datasource: Array,
			pagination: Object,
			
		},
		data() {
			return {
				selectedRowKeysArray:[],
				selectedRowsArray:[],
				user_id:""
			};
		},
		mounted() {

		},
		methods: {
			// 分页点击触发
			handleTableChange(pagination, filters, sorter) {
			  console.log(pagination);
			 this.$emit("gettabledata",pagination)
			},
			// 搜索关键次
			onSearch(value) {
				console.log(value);
				this.$emit("getsearch",value)
			},
			// 选择用户
			onSelectChange(selectedRowKeys, selectedRows){
				console.log(selectedRows,"选中行");
				this.selectedRowsArray = selectedRows
				this.selectedRowKeysArray = selectedRowKeys;
				 // this.selectedRows = selectedRows;
				this.user_id = selectedRowKeys[0]
				console.log(this.user_id,"用户id");
			}
		},
	};
</script>

<style lang="less" >
	.acard {
		background: @bg-color;

		.ainputsearch {
			width: 200px;
		}
	}
	.img-pic{
		width: 50px;
		height: 50px;
	}
	.set {
		flex-direction: column;

		.setitem {
			width: 100%;

			.sort {
				width: 40%;

				.a-input {
					width: 50px;
					padding-left: 10px;
					margin-left: 5px;
				}
			}

			.switch {
				flex: 1;

				.txt {}

				.a-switch {
					margin-left: 5px;
					padding-left: 10px;
				}
			}
		}
	}
</style>
