<template>
	<div>
		<a-modal okText="确定" cancelText="取消" v-model="show" title="绑定人员" @ok="comfirm">
			<template>
				<!-- 表单验证 -->
				<a-form ref="collectionForm" :form="form">										
					<!-- 分店名称 -->
					<a-form-item label="小店名称">
						<a-input disabled v-decorator="[
							'id',
							{
							  initialValue: record.data.id,
							  rules: [{ required: true, message: `请填写小店名称`, whitespace: true }],
							},
						  ]" />
					</a-form-item>
					
					<!-- 打款账号 -->
					<a-form-item label="用户id" >
						<a-input  disabled v-decorator="[
							'user_id',
							{
							  initialValue: record.data.userid,
							  rules: [{ required: true, message: `请填写用户id`, whitespace: true }],
							},
						  ]" />
						  <a-button type="primary" @click="openbind">
							选择用户
						  </a-button>
						  <a-modal  width="80%" okText="确定" cancelText="取消" v-model="showbind" title="绑定人员" @ok="comfirmbind">
							  <bind-table
							  ref="bindtable"
							  :columns="columns" 
							  :datasource="datasource" 
							  :pagination="pagination" 
							  
							  @gettabledata="gettabledata" 
							  @getsearch="getsearch">
							  </bind-table>
						  </a-modal>
					</a-form-item>
																																				
				</a-form>
				
			</template>


		</a-modal>
	</div>
</template>

<script>
	import {mapState,mapGetters,mapMutations,mapActions} from "vuex"
	import bindTable from "./bindtable.vue"

	export default {
		props: {
			record: Object
		},
		components:{
			bindTable
		},
		data() {
			return {
				show: false,
				isresources: false,
				form: this.$form.createForm(this, {
					name: 'coordinated'
				}),
				showbind:false,
				pagination:{
					pageSize:4,
					current:1,
					totalPage:0,
					total:0,
					keyword:"",
					platform:""
				},
				
				columns :[
				  {
				    title: 'ID',
					key:"id",
				    dataIndex: 'id',
				  },
				  {
				    title: '名称',
					key:"nickname",
				    dataIndex: 'nickname',
				  },
				  {
				    title: '手机号',
				  	key:"mobile",
				    dataIndex: 'mobile',
				  },
				  {
				    title: '余额',
					key:"balance",
				    dataIndex: 'balance',
				  },
				  {
				    title: '头像',
					key:"avatar",
				    dataIndex: 'avatar',
					slots: { title: '头像' }, //插槽列
					scopedSlots: { customRender: "avatar" },//插槽列数据
				  },
				  {
				    title: '来源平台',
				  	key:"platform",
				    dataIndex: 'platform',
				  },
				],
				datasource:""
			}
		},

		created() {
			
		},
		methods: {
			...mapActions({
				getmember:"user/getmembers",
			}),
			/*
			 *获取数据
			 *pagination 分页参数
			 * 初始化和点击执行
			 */
			async gettabledata(pagination){
				 let data = await this.getmember(pagination)
				 console.log(data,"用户");
				 this.pagination.current = pagination.current
				 this.pagination.total = data.total
				 this.pagination.totalPage = data.totalPage
				 this.datasource = data.data
			
			},
			// 打开用户列表
			async openbind(){
				console.log("111");
				// 获取表格数据
				await this.gettabledata(this.pagination)
				this.showbind = true
			},
			// 搜索用户
			getsearch(val){
				this.pagination.keyword = val
				this.pagination.current = 0
				this.gettabledata(this.pagination);
			},
			// 确定用户
			comfirmbind(e){
				console.log(e,this.$refs.bindtable.user_id,"确定用户");
				this.$nextTick(() => {
				    this.form.setFieldsValue({'user_id':this.$refs.bindtable.user_id})
				});
				
				this.showbind = false
			},
			comfirm(e) {
				console.log(e);
				const form = this.$refs.collectionForm.form;
				form.validateFields((err, values) => {
					if (err) {
						return;
					}
					console.log('Received values of form: ', values);
					// 执行提交方法
					this.$emit("modifynav", values)
				});
			},
		}
	}
</script>

<style scoped lang="less">

</style>
