var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"okText":"确定","cancelText":"取消","title":"修改菜单"},on:{"ok":_vm.comfirm},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[[_c('a-form',{ref:"collectionForm",attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"小店名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'name',
						{
						  initialValue: _vm.record.data.name,
						  rules: [{ required: true, message: `请填写小店名称`, whitespace: true }],
						},
					  ]),expression:"[\n\t\t\t\t\t\t'name',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t  initialValue: record.data.name,\n\t\t\t\t\t\t  rules: [{ required: true, message: `请填写小店名称`, whitespace: true }],\n\t\t\t\t\t\t},\n\t\t\t\t\t  ]"}]})],1),_c('a-form-item',{attrs:{"label":"打款账号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'receive_payment',
						{
						  initialValue: _vm.record.data.receive_payment,
						  rules: [{ required: true, message: `请填写打款账号`, whitespace: true }],
						},
					  ]),expression:"[\n\t\t\t\t\t\t'receive_payment',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t  initialValue: record.data.receive_payment,\n\t\t\t\t\t\t  rules: [{ required: true, message: `请填写打款账号`, whitespace: true }],\n\t\t\t\t\t\t},\n\t\t\t\t\t  ]"}]})],1)],1)]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }