<template>
	<div>
		<!-- 
	  bordered :边框线
	  columns：表头
	  slots: { title: '是否显示' }, //插槽列
	  scopedSlots: { customRender: '是否显示' },//插槽列数据
	  @change="handleTableChange" //分页、排序、筛选变化时触发
	  -->
		<a-table 
		bordered 
		:columns="columns" 
		:data-source="datasource" 
		:pagination="pagination" 
		row-key="id"
		@change="handleTableChange"
		>
		
			<template  slot="wx_qr" slot-scope="wx_qr">
				<div class="imgwrap" v-on:mouseover="showpreview" v-on:mouseleave="hidepreview">
					<img class="img-pic" :src="wx_qr" alt="" />
					<template v-if="ispreview">
						<div class="mask f-ajc" @click="handlePreview">
							<a-icon type="eye" />
						</div>
					</template>
				</div>
				
				<a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
				  <img alt="example" style="width: 100%" :src="wx_qr" />
				</a-modal>
			</template>	
		
			<template  slot="userid" slot-scope="text,record">
				<template v-if="record.userid">
					<div>
						{{record.username}}
					</div>

				</template>
				<template v-else>
					<div>
						{{"暂未绑定人员"}}
					</div>
				</template>
			</template>	
		
			<template slot="status" slot-scope="text,record">
				<template v-if="record.status == '1'">
					<a-popconfirm
						  okText="确定"
						  cancelText="取消"
						  v-if="datasource.length"
						  title="确认禁止?"
						  @confirm="() => getshow(record.id)"
						>
						  <a-button type="primary">显示</a-button>
					</a-popconfirm>
				</template>
				<template v-else>
					<a-popconfirm
						  okText="确定"
						  cancelText="取消"
						  v-if="datasource.length"
						  title="确认显示?"
						  @confirm="() => getshow(record.id)"
						>
						  <a-button type="danger">禁止</a-button>
					</a-popconfirm>
				</template>
			</template>
			
			<template slot="action" slot-scope="text,record">
				<a-button  type="primary" @click="modify(record)">
					{{"修改"}}
				</a-button>
				<a-button class="ml-30"  type="primary" @click="setcommission(record)">
					{{"设置佣金"}}
				</a-button>
				<template v-if="record.userid">
					<a-button class="ml-30" type="primary" @click="bindperson(record)">换绑</a-button>
				</template>
				<template v-else>
					<a-button class="ml-30" type="primary" @click="bindperson(record)">绑定</a-button>
				</template>
				<a-popconfirm
					okText="确定"
					cancelText="取消"
				     v-if="datasource.length"
				     title="确认显示?"
				     @confirm="() => del(record)"
				   >
<!-- 				    <a-button class="ml-15" type="danger">
				    	{{"删除"}}
				    </a-button> -->
				   </a-popconfirm>
				
			</template>

		</a-table>
	</div>
</template>
<script>
	export default {
		props: {
			columns: Array,
			datasource: Array,
			pagination: Object,
			
		},
		data() {
			return {
				show:{
					title:"确认禁止",
					txt:"显示"
				},
				previewVisible: false,
				previewImage: '',
				ispreview:false
			};
		},
		mounted() {

		},
		methods: {
			// 分页点击触发
			handleTableChange(pagination, filters, sorter) {
			  console.log(pagination);
			 this.$emit("gettabledata",pagination)
			},

			// 显示提示框
			getshow(key) {
				console.log("对话框",key);
				this.$emit("getshow", key)
			},
			// 打开操作框
			modify(record) {
				this.$emit("modify", record)
			},
			// 设置佣金
			setcommission(record){
				this.$emit("setcommission", record)
			},
			// 绑定人员
			bindperson(record){
				this.$emit("bindperson", record)
			},
			// 预览
			handlePreview(){
				this.previewVisible = true
			},
			handleCancel(){
				this.previewVisible = false
			},
			showpreview(){
				this.ispreview = true
			},
			hidepreview(){
				this.ispreview = false
			},
			// 删除操作
			del(record){
				this.$emit("del", record)
			}
		},
	};
</script>

<style lang="less" scoped>
	.imgwrap{
		position: relative;
		margin: 0 auto;
		.mask{
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 50px;
			height: 50px;
			opacity: 0.6;
			background: #4a4a4a;
			color: #fff;
		}
	}


	.set {
		flex-direction: column;

		.setitem {
			width: 100%;

			.sort {
				width: 40%;

				.a-input {
					width: 50px;
					padding-left: 10px;
					margin-left: 5px;
				}
			}

			.switch {
				flex: 1;

				.txt {}

				.a-switch {
					margin-left: 5px;
					padding-left: 10px;
				}
			}
		}
	}
</style>
